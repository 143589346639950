import { required, email } from 'vuelidate/lib/validators'
import { ZbWaitersModel } from './ZbWaitersModel'
// const sizeValidation = (value, vm) => {
//   if (!value) {
//     return true
//   }
//   const file = value
//   const mbValue = (file.size / (1024 * 1024)).toFixed(2)
//   return (mbValue <= 2)
// }

export default {
  data () {
    return {
      waiter: new ZbWaitersModel({}),
      showDatePicker: false
    }
  },
  watch: {
    showDatePicker (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  validations () {
    return {
      waiter: {
        name: { required },
        email: { required, email },
        password: { required },
        birthDate: { required }
      }
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.waiter.name.$dirty) return errors
      !this.$v.waiter.name.required && errors.push(this.$t('validator.required', { field: this.$t('waiters.name') }))
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.waiter.email.$dirty) return errors
      !this.$v.waiter.email.required && errors.push(this.$t('validator.required', { field: this.$t('waiters.email') }))
      !this.$v.waiter.email.email && errors.push(this.$t('validator.email'))
      return errors
    },
    cellphoneErrors () {
      const errors = []
      if (!this.$v.waiter.cellphone.$dirty) return errors
      !this.$v.waiter.cellphone.required && errors.push(this.$t('validator.required', { field: this.$t('waiters.cellphone') }))
      !this.$v.waiter.cellphone.numeric && errors.push(this.$t('validator.numeric', { field: this.$t('waiters.cellphone') }))
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.waiter.password.$dirty) return errors
      !this.$v.waiter.password.required && errors.push(this.$t('validator.required', { field: this.$t('waiters.password') }))
      return errors
    },
    countryCodeErrors () {
      const errors = []
      if (!this.$v.waiter.countryCode.$dirty) return errors
      !this.$v.waiter.countryCode.required && errors.push(this.$t('validator.required', { field: this.$t('waiters.countryCode') }))
      return errors
    },
    birthDateErrors () {
      const errors = []
      if (!this.$v.waiter.birthDate.$dirty) return errors
      !this.$v.waiter.birthDate.required && errors.push(this.$t('validator.required', { field: this.$t('waiters.birthDate') }))
      return errors
    },
    imageErrors () {
      const errors = []
      if (!this.waiter.auxImage) {
        if (!this.$v.waiter.image.$dirty) return errors
        !this.$v.waiter.image.required && errors.push(this.$t('validator.required', { field: this.$t('waiters.image') }))
        !this.$v.waiter.image.sizeValidation && errors.push(this.$t('validator.sizeValidation'))
      }
      return errors
    }
  },
  methods: {
    initData (param) {
      this.waiter = new ZbWaitersModel(param)
    },
    onFileChange (e) {
      const file = e
      this.waiter.previewFile = URL.createObjectURL(file)
    },
    changeDate (date) {
      this.$refs.menu.save(date)
    }
  }
}
