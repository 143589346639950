import { ServiceFactory } from '@/services/ServiceFactory'
import ZbWaitersMixin from './ZbWaitersMixin'
const WaiterService = ServiceFactory.get('WaiterService')
const ModulesService = ServiceFactory.get('ModulesService')

export default {
  data () {
    return {
      loading: false,
      headers: [
        {
          text: 'Módulo',
          align: 'start',
          sortable: false,
          value: 'module'
        },
        { text: 'Ver', value: 'view', sortable: false },
        { text: 'Crear', value: 'create', sortable: false },
        { text: 'Editar', value: 'edit', sortable: false },
        { text: 'Eliminar', value: 'delete', sortable: false }
      ],
      selected: [],
      modules: [],
      typesUser: [
        {
          text: 'Administrador',
          value: 'admin'
        },
        {
          text: 'Mesero',
          value: 'waiter'
        }
      ]
    }
  },
  props: {
    dataProp: Object
  },
  mixins: [ ZbWaitersMixin ],
  mounted () {
    this.getModules()
  },
  watch: {
    dataProp: {
      handler: 'setWaiterData',
      immediate: true
    }
  },
  computed: {
    formTitle () {
      return this.dataProp ? this.$t('waiters.editWaiter') : this.$t('waiters.addNewWaiter')
    }
  },
  methods: {
    setWaiterData () {
      if (this.dataProp) {
        this.initData(this.dataProp)
      } else {
        this.initData({})
      }
    },
    closeModal () {
      this.loading = false
      this.$emit('waiterModalData', false)
      this.$v.$reset()
      this.initData({})
    },
    async getModules () {
      try {
        const { success, modules } = await ModulesService.get()
        if (success) {
          for (let index = 0; index < modules.length; index++) {
            modules[index].view = false
            modules[index].create = false
            modules[index].edit = false
            modules[index].delete = false
          }
          this.modules = modules
        }
      } catch (e) {
        this.modules = []
      }
    },
    saveData () {
      this.loading = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const postData = {
          name: this.waiter.name,
          email: this.waiter.email,
          password: this.waiter.password,
          birthDate: this.waiter.birthDate,
          role: this.waiter.role
        }
        if (this.waiter.zbUserId) {
          WaiterService.updateWaiterFormData(this.waiter.zbUserId, postData).then((res) => {
            this.closeModal()
            this.$notify({
              title: 'Éxito',
              type: 'success',
              text: res.msg
            })
          }, (error) => {
            this.closeModal()
            this.$notify({
              title: 'Error',
              type: 'error',
              text: error.msg
            })
          })
        } else {
          WaiterService.postWaiter(postData).then((res) => {
            this.closeModal()
            this.$notify({
              title: 'Éxito',
              type: 'success',
              text: res.msg
            })
          }, (error) => {
            this.closeModal()
            this.$notify({
              title: 'Error',
              type: 'error',
              text: error.msg
            })
          })
        }
      } else {
        this.loading = false
      }
    },
    verifyImage (imageForm, previousImage) {
      if (!imageForm && previousImage) {
        return previousImage
      } else {
        return imageForm
      }
    },
    formatModules () {
      let data = []
      for (let index = 0; index < this.selected.length; index++) {
        data.push({
          zbModuleId: this.selected[index].zbModuleId,
          active: true,
          permissions: {
            VIEW: this.selected[index].view ? 'GRANTED' : 'DENIED',
            CREATE: this.selected[index].create ? 'GRANTED' : 'DENIED',
            EDIT: this.selected[index].edit ? 'GRANTED' : 'DENIED',
            DELETE: this.selected[index].delete ? 'GRANTED' : 'DENIED'
          }
        })
      }
      return data
    }
  }
}
