import { ServiceFactory } from '@/services/ServiceFactory'
import ZbWaitersModal from './waiters-modal'
const WaiterService = ServiceFactory.get('WaiterService')

export default {
  name: '',
  components: {
    ZbWaitersModal
  },
  data () {
    return {
      search: '',
      loading: false,
      currentWaiter: null,
      dialog: false,
      dialogDel: false,
      waiters: [],
      headers: [
        {
          text: this.$t('waiters.name'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$t('waiters.email'),
          value: 'email'
        },
        { text: this.$t('waiters.username'), value: 'username' },
        { text: this.$t('waiters.EditDelete'), value: 'action', sortable: false }
      ]
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    initialize () {
      WaiterService.getWaiters()
        .then(resp => {
          this.waiters = resp.members
        }).catch(() => {
          this.waiters = []
        })
    },
    editItem (item) {
      this.currentWaiter = item
      this.dialog = true
    },
    openDeleteModal (item) {
      this.currentWaiter = item
      this.dialogDel = true
    },
    closeDeleteModal () {
      this.currentWaiter = null
      this.dialogDel = false
    },
    deleteItem () {
      this.loading = true
      WaiterService.deleteWaiter(this.currentWaiter.zbUserId).then((res) => {
        this.dialogDel = false
        this.loading = false
        this.initialize()
        this.$notify({
          title: 'Éxito',
          type: 'success',
          text: res.msg
        })
      }, (error) => {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: error.msg
        })
        this.dialogDel = false
        this.loading = false
        this.initialize()
      })
    },
    openModal () {
      this.currentWaiter = null
      this.dialog = true
    },
    closeModal () {
      this.currentWaiter = null
      this.dialog = false
      this.initialize()
    }
  }
}
