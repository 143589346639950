
export class ZbWaitersModel {
  constructor (obj) {
    this.zbUserId = obj.zbUserId || null
    this.name = obj.name || null
    this.email = obj.email || null
    this.password = obj.password || null
    this.timezoneOffset = obj.timezoneOffset || 240
    this.birthDate = obj.birthDate || null
    this.role = obj.role || null
    // this.image = (_.isString(obj.image) ? null : obj.image) || null
    // this.previewFile = obj.image || null
    // this.auxImage = obj.image || null
  }
}
